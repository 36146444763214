/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	&:empty::after {
		margin-left: 0;
	}
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9375rem;
	color: #3d4e67;
	text-align: left;
	list-style: none;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #e8ebf3;
	border-radius: 3px;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}

.dropright {
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
		}
		&:empty::after {
			margin-left: 0;
		}
		&::after {
			vertical-align: 0;
		}
	}
}

.dropleft {
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			display: none;
		}
		&::before {
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
		&::before {
			vertical-align: 0;
		}
	}
}

.dropdown-menu {
	&[x-placement^="top"],
	&[x-placement^="right"],
	&[x-placement^="bottom"],
	&[x-placement^="left"] {
		right: auto;
		bottom: auto;
	}
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e8ebf3;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: $color;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.drop-icon-wrap {
	text-align: center;
	li {
		display: inline-block;
	}
	.drop-icon-item {
		display: inline-block;
		padding-bottom: 10px;
		padding-top: 10px;
		text-align: center;
		color: #a7b4c9;
		text-transform: capitalize;
		min-width: 75px;
	}
}

.drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
}

.drop-icon-wrap .drop-icon-item:hover {
	text-decoration: none;
	background-color: #f9faff;
}

.dropdown-item {
	&.user {
		font-size: 16px;
		padding: 5px;
	}
	&:hover,
	&:focus,
	&.active,
	&:active {
		text-decoration: none;
		background-color: #f9faff;
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: transparent;
	}
}

.dropdown-menu.show {
	display: block;
	margin: 15px 0 0 0;
	box-shadow: 0px 5px 25px rgba(39, 55, 76, 0.2);
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #868e96;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #a7b4c9;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	&::after {
		margin-left: 0;
	}
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}

.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
	position: absolute !important;
	width: 100% !important;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 9999;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	background-color: $white;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
	>li>a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: 400;
		line-height: 1.428571429;
		color: #333;
		white-space: nowrap;
		&:hover,
		&:focus {
			text-decoration: none;
			color: #a7b4c9;
			background-color: #f9faff;
		}
	}
	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: #e8ebf3;
	}
	.dropdown-plus-title {
		width: 100% !important;
		color: #a7b4c9 !important;
		padding: 6px 12px !important;
		font-weight: 500 !important;
		border: 0 solid #e8ebf3 !important;
		border-bottom-width: 1px !important;
		cursor: pointer !important;
	}
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
}

.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid #e8ebf3;
}

.dropdown-demo .dropdown-menu {
	position: static;
	float: none;
}

.dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #a7b4c9;
}

.dropdown-media-list {
	.media {
		&:hover,
		&:focus {
			background-color: #f9faff;
		}
		padding: 12px 15px;
	}
	img {
		border-radius: 100%;
	}
	.media-body {
		margin-left: 15px;
		> {
			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				p {
					margin-bottom: 0;
					font-weight: 500;
					color: #a7b4c9;
					font-size: 14px;
				}
				span {
					font-size: 12px;
				}
			}
			p {
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}
}

.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid #e8ebf3;
}

.dropdown-media-list {
	padding: 0;
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
		width: auto !important;
	}
}

.dropdown {
	display: block;
}

.dropdown-menu {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 12rem;
}

.dropdown {
	display: block;
}

.dropdown-menu {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 12rem;
}

.dropdown-item {
	color: $color;
}

.dropdown-menu-arrow {
	&:before {
		position: absolute;
		top: -6px;
		left: 12px;
		display: inline-block;
		border-right: 5px solid transparent;
		border-bottom: 5px solid rgba(0, 40, 100, 0.12);
		border-left: 5px solid transparent;
		border-bottom-color: $black-2;
		content: '';
	}
	&:after {
		position: absolute;
		top: -5px;
		left: 12px;
		display: inline-block;
		border-right: 5px solid transparent;
		border-bottom: 5px solid $white;
		border-left: 5px solid transparent;
		content: '';
	}
}

#sidebar .dropdown-menu {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 12rem;
}

.dropdown-menu-arrow.dropdown-menu-right {
	&:before,
	&:after {
		left: auto;
		right: 12px;
	}
}

.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	&:after {
		vertical-align: 0.155em;
	}
	&:empty:after {
		margin-left: 0;
	}
}

.dropdown-icon {
	color: #a7b4c9;
	margin-right: .5rem;
	margin-left: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
	font-size: 14px;
	font-weight: 600 !important;
}