.tab-content> {
	.tab-pane {
		display: none;
	}
	.active {
		display: block;
	}
}

@media (max-width: 480px) {
	.tab-content.card-body {
		.btn {
			min-width: 1.625rem;
		}
		.progress-bar {
			display: none;
		}
	}
}


/*-----Tabs-----*/

.tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: $white;
		border-radius: 25px;
	}
}

.tabs-menu1 ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		border-radius: 2px;
	}
}

.wideget-user-tab .tabs-menu1 ul li .active {
	border: 0;
	border-radius: 0;
}

.tabs-menu-body {
	padding: 15px;
	border: 1px solid #e8ebf3;
	p:last-child {
		margin-bottom: 0;
	}
}

.tab-menu-heading {
	padding: 20px;
	border: 1px solid #e8ebf3;
	border-bottom: 0;
}

.tab_wrapper .content_wrapper .tab_content.active p:last-child {
	margin-bottom: 0;
}

.item-card .cardbody {
	position: relative;
	padding: 30px 15px;
	border-radius: 0 0 .2rem .2rem;
}


/*-----Tabs-----*/

.tab_wrapper {
	>ul {
		li {
			border: 1px solid #e8ebf3;
			border-top: 3px solid #e8ebf3;
		}
		border-bottom: 1px solid #e8ebf3;
	}
	&.right_side {
		.content_wrapper {
			border: 1px solid #e8ebf3;
		}
		>ul {
			li {
				&.active {
					border-color: #e8ebf3;
				}
				&:after {
					background: #e8ebf3;
				}
			}
			border-bottom: 1px solid #e8ebf3;
		}
	}
}

.eductaional-tabs.nav {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.tabs-menu ul.eductaional-tabs li a {
	padding: 10px 20px 11px 20px;
	background: $secondary;
	border-radius: 23px;
	margin: 10px;
	color: $white;
}

@media (max-width: 480px) {
	.tabs-menu ul.eductaional-tabs li {
		a {
			margin: 0;
		}
		width: 100% !important;
	}
	.tab-content .tab-pane .card-body a {
		width: 100%;
		display: block;
		&.float-right {
			float: none !important;
		}
	}
}