.media-posts {
	width: 100%;
	height: 233px;
	overflow: hidden;
	&:hover {
		overflow: scroll;
	}
}

.media {
	.chat {
		i,
		a {
			font-size: 13px;
		}
	}
	display: flex;
	align-items: flex-start;
}


/*------ Media object ------*/

.mediaicon {
	font-size: 18px;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 5px 0px;
	border-radius: 20px;
}

.media-body {
	flex: 1;
}

.mediafont {
	font-size: 16px;
	font-weight: 600;
}

.media {
	margin-top: 15px;
	zoom: 1;
	overflow: hidden;
}

.media-body {
	zoom: 1;
	overflow: hidden;
}

.media-object {
	display: block;
}

.media {
	img {
		width: 45px;
		height: 45px;
	}
	&.meida-md img {
		width: 65px;
		height: 65px;
	}
	&.meida-lg img {
		width: 80px;
		height: 80px;
	}
}

.media-right,
.media>.pull-right {
	padding-left: 10px;
}

.media-body,
.media-left,
.media-right {
	display: table-cell;
	vertical-align: top;
}

.media-left,
.media>.pull-left {
	padding-right: 10px;
}

.media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.media-body h4 {
	font-weight: 500;
	font-size: 14px;
}

.media-middle {
	vertical-align: middle;
}

.media-bottom {
	vertical-align: bottom;
}

.media-left {
	padding-right: 10px;
}

.media {
	>.pull-left {
		padding-right: 10px;
	}
	display: flex;
	align-items: flex-start;
}


/*------ Media object ------*/

.media-body {
	flex: 1;
}

.media {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
}

.media-body {
	-ms-flex: 1;
	flex: 1;
}

.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}

.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
	.media-icons {
		font-size: 18px;
		color: $black;
	}
}

@media (max-width: 768px) {
	.tabs-menu1 ul li a {
		display: block;
		width: 100%;
		border: 1px solid #e8ebf3;
		margin: 1px;
		border-radius: 2px;
	}
}


/*-- media-list --*/