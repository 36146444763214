/*-----Pricing tables-----*/

.pricing {
	color: $white;
	border-radius: 7px;
}

.pricing1 {
	color: #1f252d;
	border-radius: 7px;
}

.pricing {
	.card-category {
		background: $white-3;
		padding: 10px 0;
		color: #Fff;
	}
	.list-unstyled li {
		border-bottom: 1px solid $white-1;
		padding: 7px 0;
	}
}

.pricing1 {
	.list-unstyled li {
		border-bottom: 1px solid #e8ebf3;
		padding: 7px 0;
	}
	.card-category {
		background: $white-3;
		padding: 20px 0;
		color: #Fff;
	}
}

.pricing-table {
	width: 100%;
	margin: 0 auto;
	transition: all .3s ease;
	-o-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	border-radius: 0px;
	&:hover {
		box-shadow: 0px 0px 30px $black-2;
		> {
			.panel-footer-landing {
				box-shadow: 0px 0px 30px $black-05 inset;
				-webkit-transition: all .3s ease;
			}
			.panel> {
				.panel-body-landing {
					background: #eef2f9;
					-webkit-transition: all .3s ease;
				}
				.panel-heading-landing-box {
					background: $white !important;
					color: #333 !important;
					-webkit-transition: all .3s ease;
				}
				.controle-header {
					background: #eef2f9 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
				}
				.panel-footer {
					background: #eef2f9 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
					text-align: center;
					>.btn {
						border: solid 1px $white !important;
						-webkit-transition: all .3s ease;
					}
				}
			}
		}
	}
}

.btn-price:hover {
	background: $white !important;
	color: #eef2f9 !important;
	-webkit-transition: all .3s ease;
}

.pricing-table {
	&:hover {
		>.panel>.controle-header>.panel-title-landing {
			color: $white !important;
			-webkit-transition: all .3s ease;
			font-size: 35px;
		}
		>.panel>.panel-body-landing>.table>tbody>tr>td {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	>.panel>.controle-header>.panel-title-landing {
		color: $black !important;
		font-size: 35px;
	}
}

.table> {
	tbody>tr>th {
		font-weight: 500;
		-webkit-transition: all .3s ease;
	}
	tfoot>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	thead>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
}

.col-sm-4 .pricing-table .table td {
	padding: 0.75rem;
	vertical-align: top;
	text-align: left;
}

.price {
	.list-group-item {
		border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	}
	.panel-footer {
		border-bottom: 0px;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}