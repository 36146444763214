.example .pagination {
	margin-bottom: 0;
}

.pagination {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	color: $color;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid #e8ebf3;
	&:hover {
		z-index: 2;
		text-decoration: none;
		background-color: #eaecfb;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

.page-item {
	&:first-child .page-link {
		margin-left: 0;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	&.active .page-link {
		z-index: 1;
		color: $white;
	}
	&.disabled .page-link {
		color: #ced4da;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: #e8ebf3;
	}
}

.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		.users-list .page-header {
			background: transparent;
			padding: 0;
			border: 0;
		}
		.bannerimg .breadcrumb {
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
		}
		.page-title {
			margin: 0;
			font-size: 18px;
			font-weight: 500;
			line-height: 2.5rem;
		}
		.page-title-icon {
			color: #a7b4c9;
			font-size: 1.25rem;
		}
		.page-subtitle {
			font-size: 0.8125rem;
			color: #5f6877;
			position: relative;
			top: 19px;
			right: 59rem;
			a {
				color: inherit;
			}
		}
		.page-options {
			margin-left: auto;
		}
		.page-breadcrumb {
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
		}
		.page-description {
			margin: .25rem 0 0;
			color: #5f6877;
			a {
				color: inherit;
			}
		}
		.page-single {
			-ms-flex: 1 1 auto;
			-webkit-box-flex: 1;
			flex: 1 1 auto;
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

.page-header {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flexwrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: $white;
	.page-select {
		width: 20%;
	}
}

.page {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	min-height: 100%;
}

body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0;
	}
}

.page-breadcrumb {
	background: none;
	padding: 0;
	margin: 1rem 0 0;
	font-size: 0.875rem;
	.breadcrumb-item {
		color: #a7b4c9;
		&.active {
			color: #5f6877;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float: right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}
}

.page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	color: #5f6877;
}


/*# sourceMappingURL=pagination.css.map */