.horizontal-main,
.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
	background-color: $white;
}

.horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: #504e70;
		&.btn {
			color: $white;
			font-size: 0.85rem;
		}
		>.fa {
			color: #55606c;
		}
		&.active .fa {
			color: $white;
		}
	}
	&:hover>a>.fa {
		color: $white;
	}
	> {
		a:hover .fa {
			color: $white;
		}
		ul.sub-menu>li {
			a i {
				color: #333;
			}
			&:hover>a i {
				color: $white;
			}
		}
		a.btn:hover {
			background: #fb9512;
		}
	}
}


/*--Horizontal Css--*/

@media (max-width: 992px) {
	.horizontal-header .container {
		position: relative;
	}
}